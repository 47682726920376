import Swiper from 'swiper/bundle';

jQuery(document).ready(function () {
    topScrollUp();
    navigator();
    swiperSlider();
    scrollNav();
    //loginRegistration();
    showSearch();
    miniCart();
    ajaxTrigger();
    swiperSlider();
    initializeSwiper();
    revealContent(".reveal");
    revealContent(".reveal-left");
    revealContent(".reveal-right");
    multiStep();
    updatePriceRange();
});

function initializeSwiper() {
    let swiper = new Swiper('.swiper-container', {
        slidesPerView: 1,
        slidesPerGroup: 1,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },

        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        coverflowEffect: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
        },
        autoplay: {
            delay: 4000,
            disableOnInteraction: false,
        },
    });
}

function topScrollUp() {
    const scrollToTop = $('<a href="#top" class="scroll-top-btn"></a>').appendTo('body').hide();
    $(window).scroll(() => {
        $(this).scrollTop() > 200 ? scrollToTop.fadeIn() : scrollToTop.fadeOut();
    });
    scrollToTop.click(() => {
        $('body,html').animate({
            scrollTop: 0
        }, 800);
        return false;
    });
}


const navigator = () => {
    $('.menu-item-has-children > a').click(function (e) {
        e.preventDefault();
        $(this).siblings('.sub-menu').slideToggle();
        $(this).toggleClass('expanded');
    });

    $(".menu-link").click(function (e) {
        e.preventDefault();
        $(".mobile-menu-overlay").toggleClass("open");
        $(".mobile-menu").toggleClass("open");
        if ($(".mobile-menu-overlay").hasClass("open")) {
            $("body").addClass("menu-overlay-open");
        } else {
            $("body").removeClass("menu-overlay-open");
        }
    });
};

function scrollNav() {
    let contentSection = $('.content-section');
    let navigation = $('.services');

    //when a nav link is clicked, smooth scroll to the section
    navigation.on('click', 'a', function (event) {
        event.preventDefault(); //prevents previous event
        smoothScroll($(this.hash));
    });

    //update navigation on scroll...
    $(window).on('scroll', function () {
        updateNavigation();
    })
    updateNavigation();

    /////FUNCTIONS
    function updateNavigation() {
        contentSection.each(function () {
            let sectionName = $(this).attr('id');
            let navigationMatch = $('nav a[href="#' + sectionName + '"]');
            if (($(this).offset().top - $(window).height() / 2 < $(window).scrollTop()) &&
                ($(this).offset().top + $(this).height() - $(window).height() / 2 > $(window).scrollTop())) {
                navigationMatch.addClass('active-section');
            } else {
                navigationMatch.removeClass('active-section');
            }
        });
    }

    function smoothScroll(target) {
        $('body,html').animate({
            scrollTop: target.offset().top
        }, 800);
    }
}


function loginRegistration() {
    $('#sign-up-btn').click(function () {
        $('.account-box').removeClass('slide-left').addClass('slide-right');
        $('.sign-in-box').addClass('no-display');
        $('.sign-up-box').removeClass('no-display');
    });

    $('#sign-in-btn').click(function () {
        $('.account-box').removeClass('slide-right').addClass('slide-left');
        $('.sign-up-box').addClass('no-display');
        $('.sign-in-box').removeClass('no-display');
    });
}


function showSearch() {
    let searchWrapper = $('.search-wrapper');
    let productSearch = $('.product-search');
    searchWrapper.click(function (e) {
        e.stopPropagation();
        productSearch.toggleClass('active');
    });
    $(document).click(function (e) {
        if (!searchWrapper.is(e.target) && !productSearch.is(e.target) && productSearch.has(e.target).length === 0) {
            productSearch.removeClass('active');
        }
    });
}


function miniCart() {
    $(".cart-with-count").click(function () {
        $(".mini-cart, .cart-overlay").toggleClass("mini-cart-open");
    });
    $('.cart-overlay, .close-btn').on('click', function () {
        $(".mini-cart, .cart-overlay").removeClass("mini-cart-open");
    });
}

function cartCountUpdate() {
    $.ajax({
        url: ajax.url,
        type: 'POST',
        data: {
            'action': 'update_cart_count'
        },
        success: function (response) {
            $('#ajax-cart-count').html(response);
        }
    });
}

function ajaxTrigger() {
    $('.ajax_add_to_cart, .product-remove').on('click', function () {
        console.log('working');
        cartCountUpdate();
    });
}

function swiperSlider() {
    const swiper = new Swiper('.blog-slider', {
        spaceBetween: 30,
        effect: 'fade',
        loop: true,
        mousewheel: {
            invert: false,
        },
        // autoHeight: true,
        pagination: {
            el: '.blog-slider__pagination',
            clickable: true,
        }
    });
}

function revealContent(className) {
    function isInViewport(element) {
        let elementRect = element.getBoundingClientRect();
        let windowHeight = window.innerHeight;
        let middleOfViewport = windowHeight / 1.2;

        return (
            elementRect.top < middleOfViewport && elementRect.bottom > middleOfViewport
        );
    }

    function handleScroll() {
        $(className).each(function () {
            if (!this.classList.contains("active") && isInViewport(this)) {
                this.classList.add("active");
            }
        });
    }

    $(window).on("scroll", handleScroll);
    handleScroll();
}


function multiStep() {
    // Define class names
    let checkoutStepClass = '.checkout-step';
    let stepNavigationClass = '.step-navigation';
    let stepLinkClass = '.step-navigation a';
    let activeClass = 'active';
    let nextStepClass = '.next-step';
    let prevStepClass = '.prev-step';

    // Show the first step by default
    $(stepLinkClass).first().addClass(activeClass);

    // Handle next button click
    $(nextStepClass).click(function (e) {
        e.preventDefault();
        let currentStep = $(this).closest(checkoutStepClass);
        // Validate the current step
        if (!validateStep(currentStep)) {
            return;
        }

        // existing code for transitioning to the next step
        let nextStep = currentStep.next(checkoutStepClass);
        currentStep.removeClass(activeClass);
        nextStep.addClass(activeClass);
        $(stepLinkClass + '.' + activeClass).removeClass(activeClass).next('a').addClass(activeClass);
    });

    // Handle previous button click
    $(prevStepClass).click(function (e) {
        e.preventDefault();
        let currentStep = $(this).closest(checkoutStepClass);
        let prevStep = currentStep.prev(checkoutStepClass);
        currentStep.removeClass(activeClass);
        prevStep.addClass(activeClass);
        $(stepLinkClass + '.' + activeClass).removeClass(activeClass).prev('a').addClass(activeClass);
    });

    // Function to validate the required fields in the current step
    function validateStep(step) {
        if (step.find('.woocommerce-invalid').length > 0 || step.find('.empty-field-message').length > 0) {
            if (step.find('.woocommerce-invalid').length > 0) {
                showError('Bitte geben Sie die richtigen Daten ein');
            } else {
                showError('Bitte füllen Sie das erforderliche Feld aus.');
            }
            return false;
        }

        let requiredFields = step.find('.validate-required input, .validate-required select, .validate-required textarea');
        let isValid = true;

        requiredFields.each(function () {
            let input = $(this);

            if (input.val() === '') {
                isValid = false;

                // Add a class to highlight the empty field with a red border
                input.addClass('empty-field');

                // Show a message below the input
                if (!input.next('.empty-field-message').length) {
                    $('<span class="empty-field-message">Bitte füllen Sie dieses Feld aus.</span>').insertAfter(input);
                }
            } else {
                // Remove the class and message if the field is not empty
                input.removeClass('empty-field');
                input.next('.empty-field-message').remove();
            }

            // Hide the empty-field-message on input
            input.on('input', function () {
                if ($(this).val() !== '') {
                    $(this).removeClass('empty-field');
                    $(this).next('.empty-field-message').remove();
                }
            });
        });
        return isValid;
    }


    // Function to display error message in a centered div
    function showError(message) {
        let overlay = $('<div class="custom-error-overlay"></div>');
        let errorBox = $('<div class="custom-error-box"></div>');
        let closeIcon = $('<span class="custom-error-close-icon">&times;</span>').click(function () {
            overlay.fadeOut(function () {
                overlay.remove();
            });
        });

        let errorDiv = $('<div class="custom-error-message"></div>').text(message);
        errorBox.append(closeIcon);
        errorBox.append(errorDiv);
        overlay.append(errorBox);
        $('body').append(overlay);
        overlay.fadeIn();
    }
}



function updatePriceRange() {
    let minPrice = parseInt($('#min-price-range').val());
    let maxPrice = parseInt($('#max-price-range').val());
    let maxPriceLimit = parseInt($('#max-price-range').attr('max')); // Dynamic max value

    // Ensure min is never greater than max
    if (minPrice > maxPrice) {
        let temp = minPrice;
        minPrice = maxPrice;
        maxPrice = temp;
    }

    $('#min-price-range-value').text('€' + minPrice);
    $('#max-price-range-value').text('€' + maxPrice);

    let minPercentage = (minPrice / maxPriceLimit) * 100;
    let maxPercentage = (maxPrice / maxPriceLimit) * 100;

    $('.range-track').css({
        left: minPercentage + '%',
        right: (100 - maxPercentage) + '%'
    });
}

$('#min-price-range, #max-price-range').on('input', updatePriceRange);

// Submit filter form via AJAX
$('#custom-filters').submit(function (e) {
    e.preventDefault();

    let selectedCategory = $('#category-dropdown').val();
    let minPrice = $('#min-price-range').val();
    let maxPrice = $('#max-price-range').val();

    $.ajax({
        url: ajax.url,
        type: 'GET',
        data: {
            action: 'custom_product_filter',
            category: selectedCategory,
            min_price_range: minPrice,
            max_price_range: maxPrice,
        },
        success: function (response) {
            $('.woocommerce-shop-products').hide();
            $('#filtered-products-container').html(response.data);
        },
        error: function (error) {
            console.log('Error:', error.responseText);
        }
    });
});

// Reset filters
$('#reset-filters').on('click', function () {
    $('#category-dropdown').val('');
    $('#min-price-range').val(0);
    let maxPrice = $('#max-price-range').attr('max'); // Get dynamic max price
    $('#max-price-range').val(maxPrice);
    updatePriceRange();
});
